import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import matchAuth from '../utils/matchAuth'
export default function AuthRoute(props: { children: JSX.Element }) {
  const location = useLocation()

  const isLogin = localStorage.getItem('isLogin')
  const currentPath = location.pathname

  const findPath = matchAuth(currentPath)
  /**
   * 因为下面的跳转 上面要对路径进行处理 否则就会造成 无限跳转

   */
  const condition =
    (currentPath === '/login' && !isLogin) ||
    (currentPath === '/management' && isLogin) ||
    (currentPath === '/404' && isLogin) ||
    (!isLogin && findPath?.mate?.unWantedAuth)

  if (condition) {
    return props.children
  }
  /**
   * 如果没有找到路径 但是登陆了 就进入404
   */
  if (!findPath && isLogin) {
    return <Navigate to="/404" />
  }

  /**
   * 如果没有找到路径 没登陆了 就进入登录页面
   * 没登陆也是直接进入登录页面
   */
  if ((!findPath && !isLogin) || !isLogin) {
    return <Navigate to="/login" />
  }

  /**
   * 如果等于登录页面 并且已经登录
   * 没登陆也是直接进入登录页面
   */

  if (['/login', '/'].includes(currentPath) && isLogin) {
    return <Navigate to="/management" />
  }

  // 其他路由直接进入对应页面
  return props.children

  /**
   * 如果找到元素 不需要权限直接返回
   * 否则如果登录就直接返回路由
   * 否则么有登录就直接返回登陆页面
   */
}
