// 'use client'
// App.jsx
import React from 'react'
import { HashRouter as Router } from 'react-router-dom'
// import Management from './views/management'
// import Login from './views/login'
import UseAuth from './components/useAuth'
import GetRouter from './components/getRouter'

const App = () => {
  return (
    <Router>
      <UseAuth>
        <GetRouter />
      </UseAuth>
    </Router>
  )
}

export default App
